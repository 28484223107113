import * as React from "react"
import { Link } from "gatsby"
import { FC } from "react"
import { SiteHeader } from "./header";
import { SiteFooter } from "./footer";
import * as styles from '../css/layout.module.css'

type LayoutProps={
  location: Location,
  title: string,
  children: React.ReactNode
}

const Layout: FC<LayoutProps> = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = (
      <header>
          <h1 className="main-heading">
        <Link to="/">{title}</Link>
        </h1>
      </header>

    )
  } else {
    header = (
      <Link className="header-link-home" to="/">
        {title}
      </Link>
    )
  }

  return (
    <div className={styles.layout} data-is-root-path={isRootPath}>
      <SiteHeader></SiteHeader>
      <main>{children}</main>
      <SiteFooter></SiteFooter>
    </div>
  )
}

export default Layout
