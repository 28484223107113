import { StaticImage } from "gatsby-plugin-image";
import React, { FC } from "react"
import { Link } from "gatsby"
import * as styles from '../css/header.module.css'

export const SiteHeader: FC=({children})=>{
  return (
    <header>
        <h1 className={styles.logo}>
            <Link to="/">
                <StaticImage src='../images/site-banner.png' alt="logo"></StaticImage>
            </Link>
        </h1>
        <div className={styles.navigation}>
            <ul>
                <li>
                    <Link to="/">
                        HOME
                    </Link>
                </li>
                <li>
                    <Link to="/about">
                        ABOUT
                    </Link>
                </li>
                <li>
                    <Link to="/contact">
                        CONTACT
                    </Link>
                </li>
            </ul>
        </div>
    </header>
  );
}