import { StaticImage } from "gatsby-plugin-image";
import React, { FC } from "react"
import * as styles from '../css/footer.module.css'
import { ContainerCenter } from "../components/container-center";

export const SiteFooter: FC=()=>{
  return (
    <footer>
      <ContainerCenter>
        <div className={styles.FooterContainer}>
          © {new Date().getFullYear()}, MONOBUILDERS
        </div>
      </ContainerCenter>
    </footer>
  );
}