import * as React from "react"
import { Link } from "gatsby"
import { FC } from "react"
import * as styles from '../css/container-center.module.css'

type containerCenterProps = {
  children: React.ReactNode
} 

export const ContainerCenter: FC<containerCenterProps>= ({ children }) => {
  return (
    <div className={styles.container}>
        { children }
    </div>
  )
}

